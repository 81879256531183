import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { translations } from './helper/translations'
import App from './App.vue'

import router from './router'
import store from './store/store.js'
import './assets/scss/app.scss'
import '../node_modules/tom-select/dist/css/tom-select.css';

const i18n = createI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages: translations,
})

createApp(App).use(i18n).use(router).use(store).mount('#app')
