export const form = {
    state: () => ({
        identifier: null,
        component: 'start',
        step: 'personal_data',
        stepCount: 1,
        steps: ['personal_data', 'study_situation_data', 'family_data', 'parent1_income_data', 'parent2_income_data'],
        result: 0,
    }),

    actions: {
        startForm ({ commit, state }, identifier) {
            commit('setIdentifier', identifier)
            commit('setComponent', 'form')
            commit('setStep', 'personal_data');
            commit('setStepCount', 1)
            commit('setSteps', ['personal_data', 'study_situation_data', 'family_data', 'parent1_income_data', 'parent2_income_data'])
        },

        changeIdentifier ({ commit, state }, identifier) {
            commit('setIdentifier', identifier)
        },

        changeComponent ({ commit, state }, component) {
            commit('setComponent', component)
        },

        nextStep({ commit, state }) {
            commit('setStep', state.steps[state.stepCount]);
            commit('setStepCount', state.stepCount + 1)
        },

        previousStep({ commit, state }) {
            commit('setStepCount', state.stepCount - 1)
            commit('setStep', state.steps[state.stepCount - 1]);
        },

        changeFormSteps ({ commit, state }, object) {
            commit('setStep', object.step);
            commit('setStepCount', object.stepCount)
            commit('setSteps', object.steps)
            commit('setComponent', object.component)
        },

        changeSteps ({ commit, state }, steps) {
            commit('setSteps', steps)
        },

        changeStep ({ commit, state }, step) {
            commit('setStep', step)
        },

        changeStepCount ({ commit, state }, stepCount) {
            commit('setStepCount', stepCount)
        },

        changeResult ({ commit, state }, result) {
            commit('setResult', result);
        },
    },

    mutations: {
        setIdentifier (state, identifier) {
            state.identifier = identifier;
        },
        setComponent (state, component) {
            state.component = component;
        },
        setStep (state, step) {
            state.step = step;
        },
        setStepCount (state, stepCount) {
            state.stepCount = stepCount;
        },
        setSteps (state, steps) {
            state.steps = steps;
        },
        setResult (state, result) {
            state.result = result;
        },
    },

    getters: {
        getIdentifier (state) {
            return state.identifier;
        },
        getComponent (state) {
            return state.component;
        },
        getStep (state) {
            return state.step;
        },
        getStepCount (state) {
            return state.stepCount;
        },
        getSteps (state) {
            return state.steps;
        },
        getResult (state) {
            return state.result;
        },
    }
}
